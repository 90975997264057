import * as React from 'react';
import PropTypes from 'prop-types';
import qs from 'qs';

import {ThemeProvider} from '@mui/material/styles';
import {Button, FormControl, FormHelperText, InputLabel, List, ListItem, MenuItem, Select} from "@mui/material";
import {IconButton} from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import Divider from '@mui/material/Divider';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import TextField from '@mui/material/TextField';

// import theme from '../../themes'
import darkGlobalTheme from '../../QuestUiKitDarkTheme'
import logo from "../../assets/images/velop_logo.png"
import instaLogo from "../../assets/images/insta_logo.png"
import fbLogo from "../../assets/images/fb_logo.png"
import linkedinLogo from "../../assets/images/li_logo.png"
import tiktokLogo from "../../assets/images/tiktok_logo.png"
import './Home.css';
import {useWindowWidth} from "../../breakpoints"
import axios from "axios";

// import * as constants from "../../functions/constants";

export function Home() {
  const screenWidth = useWindowWidth();
  const homeRef = React.useRef(null);
  const servicesRef = React.useRef(null);
  const contactRef = React.useRef(null);
  const [open, setOpen] = React.useState(false);

  const [selectedService, setSelectedService] = React.useState('');
  const [firstName, setName] = React.useState('');
  const [lastName, setLastName] = React.useState('');
  const [userEmail, setEmail] = React.useState('');
  const [userMessage, setMessage] = React.useState('');

  const handleServiceChange = (event) => {
    setSelectedService(event.target.value);
  };

  const handleFirstChange = (event) => {
    setName(event.target.value);
  };

  const handleLastChange = (event) => {
    setLastName(event.target.value);
  }

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  }

  const handleMessageChange = (event) => {
    setMessage(event.target.value);
  }

  const goHome = () => {
    setOpen(false);
    setTimeout(() => {
      homeRef.current?.scrollIntoView({behavior: 'smooth'});
    }, 1);
  };

  const goServices = () => {
    setOpen(false);
    setTimeout(() => {
      servicesRef.current?.scrollIntoView({behavior: 'smooth'});
    }, 1);
  };

  const goContact = () => {
    setOpen(false);
    setTimeout(() => {
      contactRef.current?.scrollIntoView({behavior: 'smooth'});
    }, 1);
  };

  const getStartedBasic = () => {
    setSelectedService('Basic Website');
    goContact();
  }

  const getStartedCustom = () => {
    setSelectedService('Custom Software');
    goContact();
  }


  async function submitForm(e) {
    e.preventDefault();

    const webhookUrl = 'https://hooks.slack.com/services/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';

    const text = `NEW LEAD!\nName: ${firstName} ${lastName} \nEmail: ${userEmail} \nInterested Service: ${selectedService} \nMessage: ${userMessage}`

    const data = {
      channel: '#web-leads',
      username: 'webhookbot',
      text: text,
      icon_emoji: ':ghost:',
    };

    try {
      const res = await axios.post(webhookUrl, qs.stringify({payload: JSON.stringify(data)}), {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      });

    } catch (error) {
      console.error(error);
    }
  }

  return (
      <ThemeProvider theme={darkGlobalTheme}>
        <div className={"desktop"} ref={homeRef}>
          <div className={"top-bar-section"}>
            <img className={"logo"} src={logo} alt={"Velop Logo"}/>
            {screenWidth > 850 &&
                <div className={'menu-bar'}>
                  <div className={"navbar-curve"}>
                    <button className={'navbar-text'} onClick={goHome}>Home</button>
                    <button className={'navbar-text'} onClick={goServices}>Services</button>
                    <button className={'navbar-text'} onClick={goContact}>Contact</button>
                  </div>
                </div>}
            {screenWidth <= 850 &&
                <IconButton className={"icon-button"}>
                  <MenuIcon className={"menu-button"} onClick={() => setOpen(true)}/>
                </IconButton>
            }
          </div>
          <div className={"sections"}>
            <div className={"header-section"}>
              <div className={"header-text-area"}>
                <h1 className={"heading-large"}>Empower Your Projects With Velop</h1>
                <div className={"line-medium"}></div>
                <p className={"heading-text"}>We develop fast software solutions for websites and custom software. At
                  Velop we harness the power of
                  AI to enhance efficiency for faster, precise results. Embrace innovation with Velop and turn your
                  vision into reality.</p>
                <Button size={"large"} color="primary" variant="contained" className={"contact-button"}
                        onClick={goContact}>GET IN
                  TOUCH</Button>
              </div>
            </div>
            <div className={"card-section"}>
              <div className={"cards"}>
                <div className={"card"}>
                  <div className={"percentage-text"}>
                    85%
                  </div>
                  <div className={"card-text"}>
                    of teams waste time weekly due to lack of collaboration
                  </div>
                </div>
                <div className={"card"}>
                  <div className={"percentage-text"}>
                    70%
                  </div>
                  <div className={"card-text"}>
                    of startups fail because of too much time spent on their development process
                  </div>
                </div>
                <div className={"card"}>
                  <div className={"percentage-text"}>
                    55%
                  </div>
                  <div className={"card-text"}>
                    of software projects fail due to lack of time to deliver the product
                  </div>
                </div>
              </div>
              <div className={"line-large"}></div>
              <div className={"card-section-text"}>
                At Velop, we value clear communication with our clients to deliver the product you need efficiently. Our
                approach reduces development time by minimizing miscommunication, resulting in a faster delivery of your
                ideal product. Let us handle the technical aspects while you concentrate on growing your business.
              </div>
            </div>
            <div className={"services-section"} ref={servicesRef}>
              <div className={"service-section-header"}>Our Services</div>
              <div className={"services"}>
                <div className={"service"}>
                  <div className={"service-header"}>Basic Website</div>
                  <div className={"service-line-long"}/>
                  <div className={"service-text"}>Personalised Design</div>
                  <div className={"service-line-short"}/>
                  <div className={"service-text"}>Hosting</div>
                  <div className={"service-line-short"}/>
                  <div className={"service-text"}>Free Domain</div>
                  <div className={"service-line-short"}/>
                  <div className={"service-text"}>Search Engine Optimisation</div>
                  <div className={"service-line-long"}/>
                  <Button size={"large"} onClick={getStartedBasic} color="primary" variant="contained"
                          className={"contact-button"}>GET
                    STARTED</Button>
                </div>
                <div className={"service"}>
                  <div className={"service-header"}>Custom Software</div>
                  <div className={"service-line-long"}/>
                  <div className={"service-text"}>Personal Discussions</div>
                  <div className={"service-line-short"}/>
                  <div className={"service-text"}>Iterative Feedback</div>
                  <div className={"service-line-short"}/>
                  <div className={"service-text"}>Secure Design</div>
                  <div className={"service-line-short"}/>
                  <div className={"service-text"}>Maintenance available</div>
                  <div className={"service-line-long"}/>
                  <Button size={"large"} onClick={getStartedCustom} color="primary" variant="contained"
                          className={"contact-button"}>GET
                    STARTED</Button>
                </div>
              </div>
            </div>
            <div className={"contact-section"} ref={contactRef}>
              <div className={"line-large"}></div>
              <div className={"contact-section-header"}>Contact Us</div>
              <div className={"contact-section-text"}>Fill in the below form or email us at sales@velop.co.nz to get in
                touch.
              </div>
              <div className={"contact-form-wrap"}>
                <div className={"contact-form"}>
                  <TextField className={"contact-text-input"}
                             id="outlined-basic"
                             value={firstName}
                             onChange={handleFirstChange}
                             label="First Name" variant="outlined"/>
                  <TextField className={"contact-text-input"}
                             id="outlined-basic"
                             value={lastName}
                             onChange={handleLastChange}
                             label="Last Name" variant="outlined"/>
                  <TextField className={"contact-text-input"}
                             id="outlined-basic"
                             value={userEmail}
                             onChange={handleEmailChange}
                             label="Email" variant="outlined"/>
                  <FormControl className={"contact-text-input"}>
                    <InputLabel id="demo-simple-select-helper-label">Interested Service</InputLabel>
                    <Select
                        labelId="demo-simple-select-helper-label"
                        id="demo-simple-select-helper"
                        value={selectedService}
                        label="Interested Service"
                        variant={"outlined"}
                        onChange={handleServiceChange}
                    >
                      <MenuItem value={"Basic Website"}>Basic Website</MenuItem>
                      <MenuItem value={"Custom Software"}>Custom Software</MenuItem>
                    </Select>
                    <FormHelperText>Select the service you would be interested in</FormHelperText>
                  </FormControl>
                  <TextField multiline
                             rows={10}
                             className={"contact-message-input"}
                             id="outlined-basic"
                             value={userMessage}
                             onChange={handleMessageChange}
                             label="Message" variant="outlined"/>
                  <Button size={"large"} color="primary" variant="contained"
                          className={"contact-button"} onClick={submitForm}>SUBMIT</Button>
                </div>
              </div>
            </div>
            <div className={"footer-section"}>
              <div className={"footer-text"}>Stay Connected Follow us on social media to stay updated with the latest
                news, industry insights, and innovative solutions from Velop.
              </div>
              <div className={"footer-icons"}>
                <a href="https://www.facebook.com/velop.nz" target="_blank" rel="noopener noreferrer">
                  <img className={"footer-icon-image"} src={fbLogo}
                       alt={"Facebook Logo"}/>
                </a>
                <a href="https://www.instagram.com/velop.nz" target="_blank" rel="noopener noreferrer">
                  <img className={"footer-icon-image"} src={instaLogo} alt={"Instagram Logo"}/>
                </a>
                <a href="https://www.linkedin.com/company/velop-nz" target="_blank" rel="noopener noreferrer">
                  <img className={"footer-icon-image"} src={linkedinLogo} alt={"LinkedIn Logo"}/>
                </a>
                <a href="https://www.tiktok.com/@velop.nz" target="_blank" rel="noopener noreferrer">
                  <img className={"footer-icon-image"} src={tiktokLogo} alt={"TikTok Logo"}/>
                </a>
              </div>
              <div className={"footer-text"}>© 2023 Velop. All rights reserved.</div>
            </div>
          </div>
        </div>
        <SwipeableDrawer className={"draw-menu"} open={open} onOpen={() => setOpen(true)} onClose={() => setOpen(false)}
                         anchor="right">
          <div>
            <IconButton>
              <ChevronRightIcon className={"chev-icon"} onClick={() => setOpen(false)}/>
            </IconButton>
          </div>
          <Divider color={"D9D9D9"}/>
          <List>
            <ListItem className={'navbar-text'} onClick={goHome}>Home</ListItem>
            <ListItem className={'navbar-text'} onClick={goServices}>Services</ListItem>
            <ListItem className={'navbar-text'} onClick={goContact}>Contact</ListItem>
          </List>
        </SwipeableDrawer>
      </ThemeProvider>
  );
}

Home.propTypes = {
  setToken: PropTypes.func.isRequired
}
