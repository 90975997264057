import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyA_1J8J3dygeIf71yZsM48zF6V_rvg-PNg",
  authDomain: "velop-demo.firebaseapp.com",
  projectId: "velop-demo",
  storageBucket: "velop-demo.appspot.com",
  messagingSenderId: "365177712800",
  appId: "1:365177712800:web:2ea88f8e9fd21f932159c5",
  measurementId: "G-J04KKEK3RW"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
